import { render, staticRenderFns } from "./AppNotifications.vue?vue&type=template&id=379349c2&scoped=true&"
import script from "./AppNotifications.vue?vue&type=script&lang=js&"
export * from "./AppNotifications.vue?vue&type=script&lang=js&"
import style0 from "./AppNotifications.vue?vue&type=style&index=0&id=379349c2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "379349c2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2107438839/src/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('379349c2')) {
      api.createRecord('379349c2', component.options)
    } else {
      api.reload('379349c2', component.options)
    }
    module.hot.accept("./AppNotifications.vue?vue&type=template&id=379349c2&scoped=true&", function () {
      api.rerender('379349c2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/elements/AppNotifications.vue"
export default component.exports
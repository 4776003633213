import Constants from '@/js/constants.js';
import { CLASSES } from '@/js/classes';
import EventBus from '@/js/event-bus.js';
import Request from '@/js/request.js';
import Utils from '@/js/utils.js';
import moment from 'moment/moment';

const c = Constants.data();

export default {
  state: {
    termObject: null,
    termSchema: null,
    searchedResults: null,
    searchedResultsCount: null,
    termNameMap: {},
    synonymList: null,
    synonymMap: null,
    downloadURL: null,
    downloadQuery: null,
    synonymIsFetching: false,
  },
  mutations: {
    setSynonymMap(state, response_body) {
      if (response_body === undefined) {
        state.synonymMap = null;
        state.synonymList = null;
        return;
      }
      let synonymMap = {};
      state.synonymList = response_body;
      for (let index = 0; index < response_body.length; index++) {
        response_body[index].forEach(synonymStr => {
          synonymMap[synonymStr.toLowerCase()] = index;
        });
      }
      state.synonymMap = synonymMap;
    },
    setPagination(state, parameters) {
      state.termObject[c.PPT_RELATIONSHIPS][parameters['edge']][
        parameters['vertex']
      ] = parameters['response_body'];
    },
    setTermSchema(state, response_body) {
      if (response_body === undefined) {
        state.termObject = null;
        state.termSchema = null;
        return;
      }
      // Assign schema
      let schema = response_body['schema'];
      schema[c.PPT_ATTRIBUTES].sort(function(a, b) {
        return a.order - b.order;
      });
      schema[c.PPT_RELATIONSHIPS].sort(function(a, b) {
        return a.order - b.order;
      });
      state.termSchema = schema;
      // Assign Term
      this.commit('setTerm', response_body['term']);
    },
    setTerm(state, term) {
      if (term === undefined) {
        state.termObject = null;
        return;
      }
      state.termObject = term;
    },
    setSearchedResults(state, response_body) {
      if (response_body === undefined) {
        state.searchedResults = null;
        state.searchedResultsCount = null;
      } else {
        state.searchedResults = response_body['items'];
        state.searchedResultsCount = response_body['count'];
      }
    },
    setTermNameMap(state, response_body) {
      let termNameMap = {};
      response_body['items'].forEach(entryObj => {
        termNameMap[entryObj[c.PPT_ID]] = entryObj;
      });
      state.termNameMap = termNameMap;
      EventBus.$emit('TermNameMapUpdated');
    },
  },
  actions: {
    setSchema({ state }, schema) {
      state.termSchema = schema;
    },
    async getTermById({ commit }, parameters) {
      let parameters_ = {};
      parameters_['locale'] = parameters['locale'];
      const response_body = await Request.get(
        `/term/${parameters['termId']}`,
        parameters_,
        parameters['refresh'],
      );
      commit('setTermSchema', response_body);
    },
    async getRelationshipPagination({ commit }, parameters) {
      let parameters_ = {};
      parameters_['start'] = parameters['start'] - 1;
      parameters_['end'] = parameters['end'];
      parameters_['edge'] = parameters['edge'];
      parameters_['vertex'] = parameters['vertex'];
      parameters_['locale'] = parameters['locale'];
      const query = `/term/${parameters['id']}`;
      const response_body = await Request.get(
        query,
        parameters_,
        parameters['refresh'],
      );
      parameters_['response_body'] = response_body;
      commit('setPagination', parameters_);
    },

    async advancedSearch({ state, commit, dispatch }, parameters) {
      await dispatch('getSynonym');
      const parameters_ = processSearchQuery(parameters);
      if (Object.keys(parameters_).length == 0) return;

      if (!parameters.hasOwnProperty('name.like')) parameters['name.like'] = '';

      let response_body = {};

      if (
        Object.keys(parameters_).length === 3 &&
        parameters_.hasOwnProperty('name.like') &&
        parameters_.hasOwnProperty('start') &&
        parameters_.hasOwnProperty('end')
      )
        response_body = await Request.get('/opensearch', parameters_);
      else response_body = await Request.get('/search', parameters_);

      state.downloadURL = null;
      commit('setSearchedResults', response_body);
      console.log(state.searchedResults);
      let searchTerm = '';
      searchTerm = parameters['name.like'].toLowerCase();

      for (let index = 0; index < state.searchedResults.length; index++) {
        if (c.PPT_TRANSLATION in state.searchedResults[index]) {
          let element = state.searchedResults[index];
          let translatedTerms = element[c.PPT_TRANSLATION];
          for (let index in translatedTerms) {
            let translatedMap = translatedTerms[index];
            let translatedTerm = translatedTerms[index].split(';')[1];
            let translatedSearchTerm = translatedTerm.toLowerCase();
            if (
              translatedSearchTerm == searchTerm &&
              translatedSearchTerm != element[c.PPT_NAME].toLowerCase()
            ) {
              element['translated'] = translatedMap;
              element[c.PPT_NAME] += `  (${translatedTerm})`;
              break;
            }
          }
        }
      }

      // Replace name for synonymous cross reference
      const searchBy = parameters['name.like'].toLowerCase();
      if (state.synonymMap.hasOwnProperty(searchBy)) {
        const synonymIndex = state.synonymMap[searchBy];
        for (
          let index = 1;
          index < state.synonymList[synonymIndex].length;
          index++
        ) {
          if (
            state.synonymList[synonymIndex][index].toLowerCase() === searchBy
          ) {
            state.searchedResults.forEach(element => {
              if (state.synonymList[synonymIndex][0] === element[c.PPT_NAME]) {
                element[
                  c.PPT_NAME
                ] += ` (${state.synonymList[synonymIndex][index]})`;
              }
            });
          }
        }
      }
    },

    async downloadSearchResult({ state }, parameters) {
      if (
        state.downloadURL !== null &&
        state.downloadQuery === parameters['download']
      )
        return;
      const parameters_ = processSearchQuery(parameters);
      if (Object.keys(parameters_).length == 0) return;

      if (state.searchedResultsCount !== null) {
        parameters_['start'] = 0;
        parameters_['end'] = state.searchedResultsCount;
      }

      const response_body = await Request.get('/search', parameters_);
      state.downloadURL = response_body;
      state.downloadQuery = parameters_['download'];
    },

    clearSearchResult({ state }) {
      state.searchedResultsCount = null;
      state.searchedResults = null;
    },

    async getEntriesName({ commit }, entryIds) {
      if (!entryIds || entryIds === '') return;
      const response_body = await Request.get('/search', {
        start: 0,
        end: 200,
        id: entryIds,
      });
      commit('setTermNameMap', response_body);
    },

    async addEntry({ commit, dispatch }, parameters) {
      if (parameters['label'] === CLASSES.CONCEPT) {
        if (!parameters['attributes'].hasOwnProperty('updateDate')) {
          parameters['attributes']['updateDate'] = moment(new Date()).format(
            'YYYY-MM-DD',
          );
        }
      }

      let parameters_ = {};
      parameters_['relationships'] = parameters['relationships_add'];
      parameters_['relationships_delete'] = parameters['relationships_delete'];
      parameters_['attributes'] = parameters['attributes'];
      parameters_['label'] = parameters['label'];
      const response_body = await Request.post('/term', parameters_);
      commit('setTerm', response_body);
      if (response_body !== undefined) {
        Utils.methods.notifySuccess('Term Entry Added.');
        dispatch('getSynonym', true);
      }
    },

    async updateEntry({ commit, dispatch }, parameters) {
      const parameters_ = {};
      if (parameters.hasOwnProperty('relationships_add')) {
        parameters_['relationships'] = parameters['relationships_add'];
      }
      if (parameters.hasOwnProperty('relationships_delete')) {
        parameters_['relationships_delete'] =
          parameters['relationships_delete'];
      }
      if (parameters.hasOwnProperty('attributes')) {
        parameters_['attributes'] = parameters['attributes'];
      }
      parameters_['label'] = parameters['label'];
      const response_body = await Request.put(
        `/term/${parameters['id']}`,
        parameters_,
      );
      commit('setTerm', response_body);
      if (response_body !== undefined) {
        Utils.methods.notifySuccess('Term Entry Updated.');
        dispatch('getSynonym', true);
      }
    },

    async deleteEntry({ commit }, entryId) {
      const response_body = await Request.delete(`/term/${entryId}`);
      if (response_body !== undefined) {
        Utils.methods.notifySuccess('Term Entry Deleted.');
        commit('setTermSchema', undefined);
      }
    },

    async getSynonym({ state, commit }, refresh = false) {
      if (state.synonymIsFetching || (!refresh && state.synonymList)) return;
      state.synonymIsFetching = true;
      const response_body = await Request.get('/synonym', {}, refresh);
      commit('setSynonymMap', response_body);
      state.synonymIsFetching = false;
    },
  },
};

function processSearchQuery(parameters) {
  const parameters_ = {};
  for (let key in parameters) {
    if (
      parameters[key] !== null &&
      parameters[key] !== undefined &&
      parameters[key] !== ''
    ) {
      parameters_[key] = parameters[key];
    }
  }
  return parameters_;
}
